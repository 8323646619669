import classNames from 'classnames';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import LiveTime from '../../components/LiveTime';
import CountryFlag from '../../components/countryFlag';
import QueryLink from '../../components/queryLink';
import SourceImage from '../../components/sourceImage';
import { DEFAULTS, IMessage, removeFakeTags } from '../../init';
import { ISourceDic } from '../../interfaces/dict';
import { PREDEFINED_SOURCES, PREDEFINED_SOURCES_SM } from '../../interfaces/sources';
import { getSourcePageLink } from '../../utils';

interface IMessageProps extends React.AllHTMLAttributes<HTMLDivElement> {
	message: IMessage;
	index: number;
	highlighting?: string[];
	matching?: number;
	countryTitle: string;
	source?: ISourceDic;
}
const Message = ({ message, index, highlighting, matching, countryTitle, source, className }: IMessageProps) => {
	const { source_id, source_title, hit_date, id, country, title, url, body } = message;
	const dt = dayjs(hit_date);

	const text = useMemo(() => {
		if (highlighting)
			return highlighting
				.filter((snippet) => snippet.trim())
				.reduce((accumulator, snippet) => (accumulator += `<p>&hellip; ${removeFakeTags(snippet)} &hellip;</p>`), '');
		if (!body) return null;
		const paragraphs = body.split('\n')?.filter((snippet) => snippet.trim());
		return paragraphs.reduce(
			(accumulator, s, index) =>
				(accumulator += `<p>${removeFakeTags(s)}${index === paragraphs.length - 1 ? '&hellip;' : ''}</p>`),
			''
		);
	}, [highlighting, body]);

	const sourceTitle = !PREDEFINED_SOURCES[PREDEFINED_SOURCES_SM].sources.includes(source_id)
		? source?.title || source_title
		: source_title;

	// const skeletonCount = (highlighting?.join(' ') || body || '').length / 80 || Math.random() * 5;

	return (
		<div className={classNames('msg__container d-flex gap-3', className)}>
			<SourceImage sourceId={source_id} title={sourceTitle} className="d-none d-sm-block" />
			<div className="d-flex flex-column w-100">
				<div className="d-flex align-items-start w-100 gap-1">
					<span className="text-wrap">
						<Link to={getSourcePageLink(source_id, dt)}>{sourceTitle}</Link>
					</span>
					<span className="text-nowrap">
						•<span className="ms-1">{dt.format('L LT')}</span>
					</span>
					{matching !== undefined && (
						<span className="text-muted text-nowrap ms-1" title="Відповідність">
							• {matching} %
						</span>
					)}
					<span className="badge rounded-pill ms-auto msg__msg-no">{index}</span>
					<CountryFlag country={source?.country || country} title={countryTitle} />
				</div>
				<div className="mb-1 d-flex justify-content-between gap-1">
					<QueryLink
						to={`post/${id}`}
						className="link-offset-2 text-decoration-on-hover h4 link-primary mb-0 message-title"
						state={DEFAULTS.fromListState}
					>
						{![498, 181].includes(source_id) ? title : source_title}
					</QueryLink>
					{url && (
						<a
							target="_blank"
							rel="noreferrer"
							className="text-decoration-none text-secondary ms-auto msg__external-link"
							href={url}
						>
							<BsBoxArrowUpRight className="mt-n1" />
						</a>
					)}
				</div>
				{text && (
					<div className="msg__snippets text-break">
						<span className="float-start">
							<LiveTime timestamp={hit_date} className="text-muted" />
							<span className="mx-1">&mdash;</span>
						</span>
						<div className="msg__snippets-text" dangerouslySetInnerHTML={{ __html: text }} />
					</div>
				)}
			</div>
		</div>
	);
};

export default Message;
