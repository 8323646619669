import dayjs from 'dayjs';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import Skeleton from 'react-loading-skeleton';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import FetchError from '../../components/FetchError';
import LiveTime from '../../components/LiveTime';
import CountryFlag from '../../components/countryFlag';
import WithCountries, { IWithCountries } from '../../components/hoc/withCountries';
import WithSources, { IWithSources } from '../../components/hoc/withSources';
import SourceImage from '../../components/sourceImage';
import { getCountryTitle } from '../../facetUtils';
import { useMessageByIdQuery } from '../../services/messages';
import { selectSearcherStore, useAppSelector } from '../../store';
import { getSourcePageLink } from '../../utils';
import MessageText from './MessageText';

interface IMessageDialogProps extends IWithCountries, IWithSources, React.AllHTMLAttributes<HTMLDivElement> {}
const MessageDialog = ({ countriesById: countries, sourcesById }: IMessageDialogProps) => {
	const { postId } = useParams();
	const [show, setShow] = useState(true);
	const navigate = useNavigate();
	const location = useLocation();

	const { preparedSearchString } = useAppSelector(selectSearcherStore);
	const { data: message, error } = useMessageByIdQuery({
		id: postId,
		term: location.state?.term ?? preparedSearchString,
	});

	const handleClose = () => {
		setShow(false);
		setTimeout(() => {
			location.state?.fromList ? navigate(-1) : navigate('..' + location.search, { replace: true });
		}, 300);
	};

	const hitDate = message ? dayjs(message.hit_date) : undefined;
	const sourceTitle = message ? sourcesById[message.source_id || -1]?.title || message.source_title : '';

	const messageTitle = message
		? ![498, 181].includes(message.source_id)
			? message.title
			: message.source_title
		: undefined;

	const country = sourcesById[message?.source_id || -1]?.country || message?.country || 'UN';
	return (
		<Modal show={show} centered size="xl" fullscreen="xl-down" onHide={handleClose}>
			<Modal.Header closeButton>
				<div className="d-flex gap-2 align-items-center minw-0 me-2">
					{message ? (
						<SourceImage sourceId={message.source_id} title={sourceTitle} size="3em" className="my-n2" />
					) : (
						<Skeleton circle containerClassName="my-n1" className="default-src-image" />
					)}
					<Modal.Title className="text-truncate">
						{message ? (
							<Link to={getSourcePageLink(message.source_id, dayjs(message.hit_date))}>{sourceTitle}</Link>
						) : (
							<Skeleton width="25vw" />
						)}
					</Modal.Title>
					{message ? (
						<CountryFlag
							country={country}
							title={`${getCountryTitle(country, countries)} (${country})`}
							className="mt-1"
						/>
					) : (
						<Skeleton className="country-flag" />
					)}
				</div>
			</Modal.Header>
			<DialogBody className="overflow-y px-5 d-flex flex-column">
				{error && <FetchError error={error} />}
				<div className="h2 text-center">{messageTitle || <Skeleton width="62%" />}</div>
				<div className="border-bottom d-flex justify-content-between mb-2">
					<div className="">{hitDate?.format('L LT') || <Skeleton width="10em" />}</div>
					{message?.hit_date ? (
						<LiveTime timestamp={message.hit_date} className="text-muted" />
					) : (
						<Skeleton width="7em" />
					)}
				</div>
				{/* <MessageText text={message?.content?.replace(reSearch, '<span class="msg-text_hl">$1</span>')} /> */}
				<MessageText text={message?.content} />
				{message ? (
					message.url && (
						<div className="text-end mt-auto">
							<a href={message.url} target="_blank" rel="noreferrer" className="text-break">
								{message.url}
								<BsBoxArrowUpRight className="mt-n1 ms-1" />
							</a>
						</div>
					)
				) : (
					<Skeleton width="38%" containerClassName="text-end mt-auto" />
				)}
			</DialogBody>
			<Modal.Body className="p-1"></Modal.Body>
		</Modal>
	);
};

export default WithSources(WithCountries(MessageDialog));

const DialogBody = styled(Modal.Body)`
	height: 80vh;
	overflow: auto;
`;
